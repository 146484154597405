import $ from 'jquery'
import * as bootstrap from 'bootstrap';

window.$ = window.jQuery = $

// zadani pro kalkulaci

var produkt = {
  nazev: '',
  technologieTisku: 'auto',
  tiskOfset: 0,
  tiskOfsetObratka: 0,
  tiskDigital: 0,
  tisk: 0,
  dtp: 0,
  get cenaCelkem() {
    return produkt.tisk + produkt.barvyDigital.cena + produkt.lak.cena + produkt.laminace.cena + produkt.falcovani.cena + produkt.bigovani.cena + produkt.vysek.cena + produkt.vysek.cenaRazniceVyseku + produkt.predsek.cena + produkt.predsek.cenaRaznicePredseku + produkt.razba.cena + produkt.lepeni.cena + produkt.vrtani.cena + produkt.perforace.cena + produkt.personalizace.cena + produkt.vazba.cena;
  },
  get cenaZaKus() {
    return produkt.tisk / produkt.pocetKusu;
  },
  pocetKusu: 0,
  vahaZakazky: 0,
  pocetArchu: 0,
  barevnost: {
    typ: '',
    text: ''
  },
  pocetBarev: 0,
  oboustranny: false,
  obratka: {
    tisk: false,
    sudyPocet: false,
    nevadiLamino: false,
    barevnost: '',
    pocetBarev: 0,
    text: ''
  },
  soubeh: false,
  primeBarvy: {
    pocet: 0,
    text: '',
  },
  barvyDigital: {
    cena: 0,
    typ: 0,
    text: '',
    textCena: '',
  },
  okrajKolemProduktu: 3,
  okrajKolemArchu: 7,
  rozmer: {
    format: 'A4',
    sirka: 210,
    vyska: 297
  },
  material: {
    nazev: 'Matná křída',
    cena: 0,
    gramaz: 115,
    rozmer: {
      sirka: 500,
      vyska: 707,
    },
  },
  laminace: {
    typ: '',
    nazev: '',
    text: '',
    cena: 0
  },
  lak: {
    typ: '',
    nazev: '',
    text: '',
    cena: 0,
  },
  falcovani: {
    typ: '',
    nazev: '',
    cena: 0,
    pocetLomu: 0
  },
  bigovani: {
    typ: '',
    nazev: '',
    cena: 0,
    pocetBigu: 0
  },
  vysek: {
    zelezo: '',
    text: '',
    cena: 0,
    pozic: 0,
    cenaRaznice: 0
  },
  predsek: {
    zelezo: '',
    text: '',
    cena: 0,
    pozic: 0,
    cenaRaznice: 0
  },
  razba: {
    stocek: '',
    text: '',
    cena: 0,
    pozic: '',
    cenaStocku: '',
    
    pozic: 0,
  },
  lepeni: {
    typ: '',
    nazev: '',
    cena: 0
  },
  vrtani: {
    pocetVrtu: 0,
    nazev: '',
    cena: 0
  },
  perforace: {
    nazev: '',
    pocetPerforaci: 0,
    cena: 0
  },
  personalizace: {
    typ: '',
    nazev: '',
    cena: 0
  },
  vazba: {
    typ: '',
    nazev: '',
    cena: 0
  }
};

var objednavka = {
  dodat: '',
  doprava: {
    cena: 0,
    nazev: ''
  },
  zabaleni: 0,
  adresa: '',
  telefonDoprava: '',
  add: function () { },
  item01: {
    // kopie stavu načteného produktu
  },
  // csvExportDPD: function (zakaznik) {
  //   const rows = [
  //     ['name1', 'city1', 'some other info'],
  //     ['name2', 'city2', 'more info'],
  //   ];
  //   let csvContent = 'data:text/csv;charset=utf-8,';
  //   rows.forEach(function (rowArray) {
  //     let row = rowArray.join(',');
  //     csvContent += row + '\r\n';
  //   });
  //   var encodedUri = encodeURI(csvContent);
  //   var link = document.createElement('a');
  //   link.setAttribute('href', encodedUri);
  //   link.setAttribute('download', 'my_data.csv');
  //   document.body.appendChild(link); // Required for FF
  //   link.click(); // This will download the data file named "my_data.csv".
  // },
};

var zakaznik = {
  kategorie: 'velko',
  fakturace: {
    ico: '',
    dic: '',
    fakturaMail: '',
  },
  doruceni: {
    adresa: '',
  }

};


// TODO: automaticky sezbírání formu simple scriptem a čistit až v backendu

// function objectifyForm(formArray) {
//   var returnArray = {};
//   for (var i = 0; i < formArray.length; i++) {
//     returnArray[formArray[i]['name']] = formArray[i]['value'];
//   }
//   return returnArray;
// }
// var formData = document.querySelector('#vypocet');
// var formObject = objectifyForm(formData);
// console.log(formObject);


// front-end cast
function getN(e) {
  return document.querySelector(e);
}

function gatherForm() {
  // load settings
  nastaveni.stroje.ofset.barvaCena = +getN('#barvaCena').value;
  nastaveni.stroje.ofset.mytiCena = +getN('#mytiCena').value;
  nastaveni.stroje.ofset.prujezdCena = +getN('#prujezdOfsetCena').value;
  nastaveni.stroje.ofset.obratkaCena = +getN('#obratkaCena').value;
  nastaveni.stroje.ofset.horniOkraj = +getN('#horniOkraj').value;
  nastaveni.stroje.ofset.dolniOkraj = +getN('#dolniOkraj').value;
  nastaveni.stroje.ofset.pravyOkraj = +getN('#pravyOkraj').value;
  nastaveni.stroje.ofset.levyOkraj = +getN('#levyOkraj').value;
  produkt.okrajKolemProduktu = +getN('#okrajKolemProduktu').value;
  produkt.okrajKolemArchu = +getN('#okrajKolemArchu').value;
  nastaveni.koeficientCena = +getN('#koeficientCena').value;
  nastaveni.dph = +getN('#dph').value;
  nastaveni.stroje.digital.pripravaCernobile = +getN('#pripravaCernobile').value;
  nastaveni.stroje.digital.pripravaBarva = +getN('#pripravaBarva').value;
  nastaveni.stroje.digital.pripravaBarvaSpecial = +getN('#pripravaBarvaSpecial').value;
  nastaveni.stroje.digital.prujezdDigiCenaDo750_44 = +getN('#prujezdDigiCenaDo750_44').value;
  nastaveni.stroje.digital.prujezdDigiCenaNad750_44 = +getN('#prujezdDigiCenaNad750_44').value;
  nastaveni.stroje.digital.prujezdDigiCenaDo750_40 = +getN('#prujezdDigiCenaDo750_40').value;
  nastaveni.stroje.digital.prujezdDigiCenaNad750_40 = +getN('#prujezdDigiCenaNad750_40').value;
  nastaveni.stroje.digital.prujezdDigiCenaDo750_11 = +getN('#prujezdDigiCenaDo750_11').value;
  nastaveni.stroje.digital.prujezdDigiCenaNad750_11 = +getN('#prujezdDigiCenaNad750_11').value;
  nastaveni.stroje.digital.prujezdDigiCenaDo750_10 = +getN('#prujezdDigiCenaDo750_10').value;
  nastaveni.stroje.digital.prujezdDigiCenaNad750_10 = +getN('#prujezdDigiCenaNad750_10').value;
  nastaveni.stroje.digital.barvySpecialLak50 = +getN('#barvySpecialLak50').value;
  nastaveni.stroje.digital.barvySpecialLak100 = +getN('#barvySpecialLak100').value;
  nastaveni.stroje.digital.barvySpecialBila50 = +getN('#barvySpecialBila50').value;
  nastaveni.stroje.digital.barvySpecialBila100 = +getN('#barvySpecialBila100').value;
  nastaveni.stroje.digital.barvySpecialZlata50 = +getN('#barvySpecialZlata50').value;
  nastaveni.stroje.digital.barvySpecialZlata100 = +getN('#barvySpecialZlata100').value;

  // load product settings
  produkt.nazev = getN('#nazev').value;
  produkt.dtp = +getN('#dtp').value;
  objednavka.doprava.cena = +getN('#doprava option:checked').value;
  objednavka.doprava.text = getN('#doprava option:checked').text;
  if (objednavka.doprava.text == 'Bez dopravy') {
    objednavka.doprava.text = "";
  } else {
    objednavka.doprava.text = 'Doprava: ' + objednavka.doprava.text + '<br>';
  }
  produkt.pocetKusu = +getN('#pocetKusu').value;
  produkt.technologieTisku = getN('#technologie option:checked').value;
  nastaveni.stroje.ofset.obratka = getN('#obratka').checked;
  produkt.soubeh = getN('#soubeh').checked;
  zakaznik.kategorie = getN('#typCeny option:checked').value;
  console.log(zakaznik.kategorie);
  produkt.barevnost.typ = getN('#pocetBarev option:checked').value;
  produkt.barevnost.text = getN('#pocetBarev option:checked').text;
  produkt.primeBarvy.pocet = +getN('#primeBarvy').value;

  produkt.barvyDigital.typ = getN('#barvyDigital option:checked').value;
  produkt.barvyDigital.nazev = getN('#barvyDigital option:checked').text;

  produkt.rozmer.format = getN('#produktRozmerFormat option:checked').text;
  if (produkt.rozmer.format === 'Vlastní rozměr') {
    produkt.rozmer.sirka = +getN('#produktRozmerSirka').value;
    produkt.rozmer.vyska = +getN('#produktRozmerVyska').value;
    produkt.rozmer.format = produkt.rozmer.sirka + '×' + produkt.rozmer.vyska + ' mm';
  } else {
    produkt.rozmer.sirka = +getN('#produktRozmerFormat option:checked').getAttribute('data-sirka');
    produkt.rozmer.vyska = +getN('#produktRozmerFormat option:checked').getAttribute('data-vyska');
    getN('#produktRozmerSirka').value = produkt.rozmer.sirka;
    getN('#produktRozmerVyska').value = produkt.rozmer.vyska;
  }

  if (getN('#material option:checked').value === 'vlastni') { // vlastni materiál
    // console.log('vlastni material');
    produkt.material.nazev = getN('#material option:checked').text;
    produkt.material.cena = +getN('#materialCena').value;
    produkt.material.gramaz = +getN('#materialGramaz').value;
    produkt.material.rozmer.sirka = +getN('#materialRozmerSirka').value;
    produkt.material.rozmer.vyska = +getN('#materialRozmerVyska').value;
  } else {
    // console.log('vybrany material');
    produkt.material.nazev = getN('#material option:checked').text;
    produkt.material.cena = +getN('#material option:checked').value;
    produkt.material.gramaz = +getN('#material option:checked').getAttribute('data-gramaz');
    produkt.material.rozmer.sirka = +getN('#material option:checked').getAttribute('data-vyska');
    produkt.material.rozmer.vyska = +getN('#material option:checked').getAttribute('data-sirka');
    getN('#materialCena').value = produkt.material.cena;
    getN('#materialGramaz').value = produkt.material.gramaz;
    getN('#materialRozmerSirka').value = produkt.material.rozmer.sirka;
    getN('#materialRozmerVyska').value = produkt.material.rozmer.vyska;
  }

  produkt.laminace.typ = getN('#laminace option:checked').value;
  produkt.laminace.nazev = getN('#laminace option:checked').text;

  produkt.lak.typ = getN('#lakovani option:checked').value;
  produkt.lak.nazev = getN('#lakovani option:checked').text;

  produkt.falcovani.typ = getN('#falcovani option:checked').value;
  produkt.falcovani.nazev = getN('#falcovani option:checked').text;

  produkt.bigovani.typ = getN('#bigovani option:checked').value;
  produkt.bigovani.nazev = getN('#bigovani option:checked').text;

  produkt.vysek.option = +getN('#vysek option:checked').value;
  produkt.vysek.typ = getN('#vysek option:checked').text;
  produkt.vysek.pozicNaVysek = +getN('#pozicNaVysek').value;
  produkt.vysek.cenaRazniceVyseku = +getN('#cenaRazniceVyseku').value;

  produkt.predsek.option = +getN('#predsek option:checked').value;
  produkt.predsek.typ = getN('#predsek option:checked').text;
  produkt.predsek.pozicNaPredsek = +getN('#pozicNaPredsek').value;
  produkt.predsek.cenaRaznicePredseku = +getN('#cenaRaznicePredseku').value;


  produkt.razba.option = +getN('#razba option:checked').value;
  produkt.razba.typ = getN('#razba option:checked').text;
  produkt.razba.pozicNaRazbu = getN('#pozicNaRazbu').value;
  produkt.razba.cenaStockuRazby = getN('#cenaStockuRazby').value;



  produkt.lepeni.typ = getN('#lepeni option:checked').value;
  produkt.lepeni.nazev = getN('#lepeni option:checked').text;

  produkt.vrtani.pocetVrtu = +getN('#vrtani option:checked').value;
  produkt.vrtani.nazev = getN('#vrtani option:checked').text;

  produkt.perforace.pocetPerforaci = +getN('#perforace option:checked').value;
  produkt.perforace.nazev = getN('#perforace option:checked').text;

  produkt.personalizace.typ = getN('#personalizace option:checked').value;
  produkt.personalizace.nazev = getN('#personalizace option:checked').text;

  produkt.vazba.typ = getN('#vazba option:checked').value;
  produkt.vazba.nazev = getN('#vazba option:checked').text;

  objednavka.dodat = getN('#dodat').value;
  objednavka.zabaleni = getN('#zabaleni').value;

  zakaznik.fakturace = getN('#fakturace').value;
  zakaznik.fakturaMail = getN('#fakturaMail').value;
  zakaznik.adresa = getN('#adresa').value;
  zakaznik.telefonDoprava = getN('#telefonDoprava').value;

  // console.log(produkt.barvyDigital.typ);

  return [produkt, nastaveni, zakaznik];
}

// EVENTY FORMU

// pokud upravíme cenu za arch/gramáž/rozměry, přehodíme select Materiál na vlastní
document.querySelectorAll('#materialCena, #materialGramaz, #materialRozmerSirka, #materialRozmerVyska').forEach(function (item) {
  item.addEventListener('keyup', function () {
    document.querySelector('#material').value = 'vlastni';
  });
  item.addEventListener('change', function () {
    document.querySelector('#material').value = 'vlastni';
  })
});

document.querySelectorAll('#produktRozmerSirka, #produktRozmerVyska').forEach(function (item) {
  item.addEventListener('keyup', function () {
    document.querySelector('#produktRozmerFormat').value = 'vlastni';
  });
  item.addEventListener('change', function () {
    document.querySelector('#produktRozmerFormat').value = 'vlastni';
  })
});

// změna ve formuláři, počítej znovu
document.querySelector('#vypocet').addEventListener('keyup', function () {  pocitej(); });
document.querySelector('#vypocet').addEventListener('change', function () {  pocitej();});
document.querySelector('#ceny').addEventListener('keyup', function () {  pocitej(); });
document.querySelector('#ceny').addEventListener('change', function () {  pocitej();});




// VÝPOČET (back-end)

// back-end DB
var nastaveni = {
  uzivatel: null,
  doprava: {
    osobni: 0,
    dpd: 100,
    ceskaposta: 120,
    tria: 0,
    zdarmaOd: 3000
  }, 
  koeficientCena: 1.2,
  dph: 1.21,
  stroje: {
    ofset: {
      horniOkraj: 17,
      dolniOkraj: 15,
      pravyOkraj: 10,
      levyOkraj: 10,
      rozmer: {
        format: 'Arch',
        sirka: 650,
        vyska: 480,
      },
      barvaCena: 500,
      mytiCena: 250,
      prujezdCena: 0.25,
      obratkaCena: 350,
      obratka: 350
    },
    digital: {
      rozmer: { // max. formát 330 × 650
        format: 'SRA3',
        sirka: 320,
        vyska: 450,
      },
      pripravaCernobile: 200,
      pripravaBarva: 200,
      prujezd: 0, // nastavi se podle poctu barev viz níže:
      prujezdDigiCenaDo750_44: 5.74,
      prujezdDigiCenaNad750_44: 3.3,
      prujezdDigiCenaDo750_40: 4.74,
      prujezdDigiCenaNad750_40: 2.3,
      prujezdDigiCenaDo750_11: 2.28,
      prujezdDigiCenaNad750_11: 1.28,
      prujezdDigiCenaDo750_10: 1.96,
      prujezdDigiCenaNad750_10: 0.96,
      pripravaBarvaSpecial: 0,
      barvySpecialLak50: 4,
      barvySpecialLak100: 8,
      barvySpecialBila50: 6,
      barvySpecialBila100: 12,
      barvySpecialZlata50: 6,
      barvySpecialZlata100: 12,
    },
  },
  lak: {
    parcial: {
      priprava10: 1700,
      priprava11: 3400,
      prujezd10: 2.5,
      prujezd11: 5,
    },
    plocha: {
      priprava10: 1300,
      priprava11: 1300,
      prujezd10: 2.5,
      prujezd11: 5,
    },
    stroj: {
      priprava10: 250,
      priprava11: 250,
      prujezd10: 0.2,
      prujezd11: 0.4,
    },
  },
  laminace: {
    cenaMinimum: 200,
    lesk: {
      prujezd10: 2.5,
      prujezd11: 5,
    },
    mat: {
      prujezd10: 3.5,
      prujezd11: 7,
    },
    struktura: {
      prujezd10: 7.5,
      prujezd11: 15,
    },
    samet: {
      prujezd10: 7.5,
      prujezd11: 15,
    },
    kapsova: {
      priprava: 100,
      a5: 12,
      a4: 23,
      a3: 33,
    }
  },
  falcovani: {
    rozmeryMinX: 148,
    rozmeryMinY: 40,
    rozmeryMaxZ: 353,
    rozmeryMaxY: 500,
    typ: {
      half_fold: {
        priprava: 100,
        cena: 0.1
      },
      cross_fold: {
        priprava: 200,
        cena: 0.2
      },
      u_fold: {
        priprava: 100,
        cena: 0.2
      },
      z_fold: {
        priprava: 100,
        cena: 0.2
      },
      double_half_fold: {
        priprava: 100,
        cena: 0.2
      },
      roll_fold: {
        priprava: 100,
        cena: 0.70
      },
      harmonium_fold_3: {
        priprava: 100,
        cena: 0.30
      },
      harmonium_fold_4: {
        priprava: 100,
        cena: 0.40
      },
      harmonium_fold_5: {
        priprava: 100,
        cena: 1
      },
      gate_fold: {
        priprava: 100,
        cena: 0.6
      },
      double_gate_fold: {
        priprava: 100,
        cena: 0.7
      }
    },
    ohyb: 0.6,
    pretaceni: 0.4
  },
  bigovani: {
    rozmeryMinX: 148,
    rozmeryMinY: 40,
    rozmeryMaxX: 353,
    rozmeryMaxY: 500,
    typ: {
      half_fold: {
        priprava: 100,
        cena: 0.40
      },
      cross_fold: {
        priprava: 100,
        cena: 0.80
      },
      u_fold: {
        priprava: 100,
        cena: 0.80
      },
      z_fold: {
        priprava: 100,
        cena: 0.80
      },
      double_half_fold: {
        priprava: 100,
        cena: 1.20
      },
      roll_fold: {
        priprava: 100,
        cena: 1.20
      },
      harmonium_fold_3: {
        priprava: 100,
        cena: 1.20
      },
      harmonium_fold_4: {
        priprava: 100,
        cena: 1.60
      },
      harmonium_fold_5: {
        priprava: 100,
        cena: 1.60
      },
      gate_fold: {
        priprava: 100,
        cena: 0.80
      },
      double_gate_fold: {
        priprava: 100,
        cena: 1.20
      }
    },
    ohyb: 0.6,
    pretaceni: 0.4
  },
  vysek: {
    priprava: 350,
    cena: 1,
    rozmeryMinX: 148.5,
    rozmeryMinY: 210,
    rozmeryMaxX: 700,
    rozmeryMaxY: 500,
  },
  predsek: {
    priprava: 500,
    cena: 1,
    rozmeryMinX: 148.5,
    rozmeryMinY: 210,
    rozmeryMaxX: 700,
    rozmeryMaxY: 500,
  },
  razba: {
    priprava: 350,
    cena: 1,
    rozmeryMinX: 148.5,
    rozmeryMinY: 210,
    rozmeryMaxX: 700,
    rozmeryMaxY: 500,
  },
  vrtani: {
    priprava: 160
  },
  perforace: {
    priprava: 100
  }
};


function pocitej() {
  // sezbíráme form
  var [produkt, nastaveni, zakaznik] = gatherForm();
  // console.log(produkt.barevnost.typ);

  // universální funkce: zaokrouhlit na dvě desetinná místa
  function zaokrouhlit(cislo) {
    return Math.round(cislo * 100) / 100;
  }


  // setup
  if (produkt.barevnost.typ === '4/4') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 8 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '4/0') {
    produkt.oboustranny = false;
    produkt.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '1/1') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 2 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '1/0';
    produkt.obratka.pocetBarev = 1 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '1/0') {
    produkt.oboustranny = false;
    produkt.pocetBarev = 1 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '4/1') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 5 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '2/0') {
    produkt.oboustranny = false;
    produkt.pocetBarev = 2 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '2/1') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 3 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '3/0';
    produkt.obratka.pocetBarev = 3 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '2/2') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 4 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '3/0') {
    produkt.oboustranny = false;
    produkt.pocetBarev = 3 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '3/1') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 4 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '3/2') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 5 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '3/3') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 6 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '4/2') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 6 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '4/3') {
    produkt.oboustranny = true;
    produkt.pocetBarev = 7 + produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '4/0';
    produkt.obratka.pocetBarev = 4 + produkt.primeBarvy.pocet;

  } else if (produkt.barevnost.typ === '0/0') {
    produkt.oboustranny = true;
    produkt.pocetBarev = produkt.primeBarvy.pocet;
    produkt.obratka.barevnost = '0/0';
    produkt.obratka.pocetBarev = produkt.primeBarvy.pocet;
  }


  // VYŘAZENÍ
  function pocetKusuNaArchu(technologie) {
    let potisknutelnaVyskaArchu, potisknutelnaSirkaArchu;
    let pocetKusuVyskaNaVysku, pocetKusuSirkaNaSirku;
    let pocetKusuVyskaNaSirku, pocetKusuSirkaNaVysku;
    let kusuNaVysku, kusuNaSirku;
    let pocet;

    if (technologie === 'ofset') {
      // vypočteme potisknutelnou plochu z archu (default 650 x 480) a spočítáme kolik se vejde kusů na arch při orientaci na výšku i šířku a zjistíme, kde se vejde větší množství
      potisknutelnaVyskaArchu = nastaveni.stroje.ofset.rozmer.vyska - nastaveni.stroje.ofset.horniOkraj - nastaveni.stroje.ofset.dolniOkraj - (produkt.okrajKolemArchu * 2);
      potisknutelnaSirkaArchu = nastaveni.stroje.ofset.rozmer.sirka - nastaveni.stroje.ofset.pravyOkraj - nastaveni.stroje.ofset.levyOkraj - (produkt.okrajKolemArchu * 2);

      // orientace produtku na výšku
      pocetKusuVyskaNaVysku = Math.floor(potisknutelnaVyskaArchu / (produkt.rozmer.vyska + produkt.okrajKolemProduktu));
      // console.log('potisknutelna Vyska Archu ofset: ' + potisknutelnaVyskaArchu + ' vyska produktu: ' + produkt.rozmer.vyska + ' okraj kolem produktu: ' + produkt.okrajKolemProduktu + ' pocetKusuVyskaNaVysku ofset: ' + pocetKusuVyskaNaVysku);
      pocetKusuSirkaNaSirku = Math.floor(potisknutelnaSirkaArchu / (produkt.rozmer.sirka + produkt.okrajKolemProduktu));
      // console.log('potisknutelna Sirka Archu ofset: ' + potisknutelnaSirkaArchu + ' sirka produktu: ' + produkt.rozmer.sirka + ' okraj kolem produktu: ' + produkt.okrajKolemProduktu + ' pocetKusuSirkaNaSirku ofset: ' + pocetKusuSirkaNaSirku);
      kusuNaVysku = pocetKusuVyskaNaVysku * pocetKusuSirkaNaSirku;
      // console.log('kusu Na Vysku ofset: ' + kusuNaVysku);

      // orientace na šířku
      pocetKusuVyskaNaSirku = Math.floor(potisknutelnaVyskaArchu / (produkt.rozmer.sirka + produkt.okrajKolemProduktu));
      // console.log('potisknutelnaVyskaArchu ofset: ' + potisknutelnaVyskaArchu + ' sirka produktu: ' + produkt.rozmer.sirka + ' okraj kolem produktu: ' + produkt.okrajKolemProduktu + ' pocetKusuVyskaNaSirku ofset: ' + pocetKusuVyskaNaSirku);
      pocetKusuSirkaNaVysku = Math.floor(potisknutelnaSirkaArchu / (produkt.rozmer.vyska + produkt.okrajKolemProduktu));
      // console.log('potisknutelnaSirkaArchu ofset: ' + potisknutelnaSirkaArchu + ' vyska produktu: ' + produkt.rozmer.vyska + ' okraj kolem produktu: ' + produkt.okrajKolemProduktu + ' pocetKusuSirkaNaVysku ofset: ' + pocetKusuSirkaNaVysku);
      kusuNaSirku = pocetKusuVyskaNaSirku * pocetKusuSirkaNaVysku;
      // console.log('kusuNaSirku ofset: ' + kusuNaSirku);

      if (kusuNaVysku < kusuNaSirku) {
        pocet = kusuNaSirku;
        produkt.orientace = 'na šířku';
      } else {
        pocet = kusuNaVysku;
        produkt.orientace = 'na výšku';
      }

      if ((pocet % 2) === 0) {
        produkt.obratka.sudyPocet = true;
        // console.log('sudy počet pro obrátku!');
      }
      // console.log(technologie + ' při orientaci na ' + produkt.orientace + ' se vejde ' + pocet + ' ks');
      // console.log('----');
      return pocet;

    } else if (technologie === 'digital') { // SRA3, u digitálu se může tisknout do kraje, ořezky můžou jít na doraz
      potisknutelnaVyskaArchu = nastaveni.stroje.digital.rozmer.vyska - (produkt.okrajKolemArchu * 2);
      potisknutelnaSirkaArchu = nastaveni.stroje.digital.rozmer.sirka - (produkt.okrajKolemArchu * 2);

      // orientace na výšku
      pocetKusuVyskaNaVysku = Math.floor(potisknutelnaVyskaArchu / (produkt.rozmer.vyska + produkt.okrajKolemProduktu));
      // console.log('potisknutelna Vyska Archu digital: ' + potisknutelnaVyskaArchu + ' vyska produktu: ' + produkt.rozmer.vyska + ' okraj kolem produktu: ' + produkt.okrajKolemProduktu + 'pocetKusuVyskaNaVysku digital: ' + pocetKusuVyskaNaVysku);
      pocetKusuSirkaNaSirku = Math.floor(potisknutelnaSirkaArchu / (produkt.rozmer.sirka + produkt.okrajKolemProduktu));
      // console.log('potisknutelna Sirka Archu digital: ' + potisknutelnaSirkaArchu + ' sirka produktu: ' + produkt.rozmer.sirka + ' okraj kolem produktu: ' + produkt.okrajKolemProduktu + 'pocetKusuSirkaNaSirku digital: ' + pocetKusuSirkaNaSirku);
      kusuNaVysku = pocetKusuVyskaNaVysku * pocetKusuSirkaNaSirku;
      // console.log('kusuNaVysku digital: ' + kusuNaVysku);

      // orientace na šířku
      pocetKusuVyskaNaSirku = Math.floor(potisknutelnaVyskaArchu / (produkt.rozmer.sirka + produkt.okrajKolemProduktu));
      // console.log('pocetKusuVyskaNaSirku digital: ' + pocetKusuVyskaNaSirku);
      pocetKusuSirkaNaVysku = Math.floor(potisknutelnaSirkaArchu / (produkt.rozmer.vyska + produkt.okrajKolemProduktu));
      // console.log('pocetKusuSirkaNaVysku digital: ' + pocetKusuSirkaNaVysku);

      kusuNaSirku = pocetKusuVyskaNaSirku * pocetKusuSirkaNaVysku;
      // console.log('kusuNaSirku digital: ' + kusuNaSirku);

      if (kusuNaVysku < kusuNaSirku) {
        pocet = kusuNaSirku;
        produkt.orientace = 'na šířku';
      } else {
        pocet = kusuNaVysku;
        produkt.orientace = 'na výšku';
      }

      // console.log(technologie + ' při orientaci na ' + produkt.orientace + ' se vejde ' + pocet + ' ks');
      return pocet;
    }
  }


  // známe počet kusů, které se vejdou na arch - vypočítáme počet archů a jejich cenu
  // console.log('material cena za arch: ' + produkt.material.cena);

  produkt.pocetArchuOfset = Math.ceil(produkt.pocetKusu / pocetKusuNaArchu('ofset'));
  // console.log('--pocet archu ofset: ' + produkt.pocetArchuOfset);
  produkt.materialCenaOfset = produkt.pocetArchuOfset * produkt.material.cena;
  // console.log('material cena ofset: ' + produkt.materialCenaOfset);

  produkt.pocetArchuDigital = Math.ceil(produkt.pocetKusu / pocetKusuNaArchu('digital'));
  // console.log('--pocet sra3 digital: ' + produkt.pocetArchuDigital);
  produkt.materialCenaDigital = produkt.pocetArchuDigital * (produkt.material.cena / 2);
  // console.log('material cena digital: ' + produkt.materialCenaDigital);



  // LAMINACE musí být před funkcí tisku, kvůli obrátce
  function laminace(typ) {
    let cena = 0;
    switch (typ) {
      case 'lesk10':
        cena = nastaveni.laminace.lesk.prujezd10 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = false;
        break;
      case 'lesk11':
        cena = nastaveni.laminace.lesk.prujezd11 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = true;
        break;
      case 'mat10':
        cena = nastaveni.laminace.mat.prujezd10 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = false;
        break;
      case 'mat11':
        cena = nastaveni.laminace.mat.prujezd11 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = true;
        break;
      case 'struktura10':
        cena = nastaveni.laminace.struktura.prujezd10 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = false;
        break;
      case 'struktura11':
        cena = nastaveni.laminace.struktura.prujezd11 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = true;
        break;
      case 'samet10':
        cena = nastaveni.laminace.samet.prujezd10 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = false;
        break;
      case 'samet11':
        cena = nastaveni.laminace.samet.prujezd11 * produkt.pocetArchu;
        produkt.obratka.nevadiLamino = true;
        break;
      case 'kapsaA5':
        cena = nastaveni.laminace.kapsova.priprava + (nastaveni.laminace.kapsova.a5 * produkt.pocetKusu);
        produkt.obratka.nevadiLamino = false;
        break;
      case 'kapsaA4':
        cena = nastaveni.laminace.kapsova.priprava + (nastaveni.laminace.kapsova.a4 * produkt.pocetKusu);
        produkt.obratka.nevadiLamino = false;
        break;
      case 'kapsaA3':
        cena = nastaveni.laminace.kapsova.priprava + (nastaveni.laminace.kapsova.a3 * produkt.pocetKusu);
        produkt.obratka.nevadiLamino = false;
        break;
      default:
        produkt.obratka.nevadiLamino = true;
    }
    if (typ !== '0' && cena < nastaveni.laminace.cenaMinimum) {
      cena = nastaveni.laminace.cenaMinimum;
    }
    return cena;
  }
  produkt.laminace.cena = (nastaveni.koeficientCena * laminace(produkt.laminace.typ));
  // textový výstup
  if (produkt.laminace.typ !== '0') {
    produkt.laminace.text = ', ' + produkt.laminace.nazev;
    produkt.laminace.textCena = 'Cena laminace: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.laminace.cena))) + ' Kč<br>';
  } else {
    produkt.laminace.text = '';
    produkt.laminace.textCena = '';
    produkt.obratka.nevadiLamino = true;
  }

  // console.log('Laminace: ' + produkt.laminace.cena);

  // TISK OFSET
  // ofset: cena a2= 500.b +250.m + 0,25.p + obrátka 350,- + materiál
  // b= počet barev, m=počet mytí ( na CMYK se nepočítá), p= počet průjezdů, g= gramáž papíru
  // pokud není obrátka, tak 0, Pokud se u brožur tiskne více stejných archů, množstevní sleva 20%

  // var cenaObratka = 0;
  // změníme množství na 2x, ale výsledná cena se vydělí 2.
  // if (produkt.obratka === true){
  //   cenaObratka = nastaveni.stroje.ofset.obratkaCena;
  // }
  // if (produkt.oboustranny === true && produkt.obratka === false) {

  // } else if (produkt.oboustranny === true && produkt.obratka === true) {
  //   produkt.pocetArchuOfset = Math.ceil(produkt.pocetKusu / (pocetKusuNaArchu('ofset') / 2));
  //   produkt.pocetArchuOfset = produkt.pocetArchuOfset * 2;
  //   var cena = (nastaveni.stroje.ofset.barvaCena * produkt.pocetBarev) + cenaMyti + cenaObratka + (nastaveni.stroje.ofset.prujezdCena * (produkt.pocetArchuOfset * 2)) + produkt.materialCenaOfset; // todo: nastaveni.stroje.ofset.obratkaCena


  function calcOfset() {
    let cenaMyti = 0;
    let cena;
    let pruchoduStrojem = 1;

    if (produkt.primeBarvy.pocet > 0) { // je přímá barva, za každou barvu poplatek za mytí
      cenaMyti = nastaveni.stroje.ofset.mytiCena * produkt.primeBarvy.pocet;
    }

    // obratka je povolena, produkt oboustranny, je to sudy počet, lamino nevadí
    // console.log('povoleno: ' + nastaveni.stroje.ofset.obratka + ' oboustranný: ' + produkt.oboustranny + ' sudý počet: '+produkt.obratka.sudyPocet + ' nevadí lamino:'+produkt.obratka.nevadiLamino);
    if (nastaveni.stroje.ofset.obratka === true && produkt.oboustranny === true && produkt.obratka.sudyPocet === true && produkt.obratka.nevadiLamino === true) {
      produkt.obratka.tisk = true; // tiskneme na obratku
      produkt.obratka.text = ', <strong>vyřazení a tisk na obrátku</strong>';
    } else {
      produkt.obratka.tisk = false; // tiskneme na obratku
      produkt.obratka.text = '';
    }

    if (produkt.oboustranny === true) { // archy jdou dvakrat do stroje
      pruchoduStrojem = 2;
    }

    if (produkt.barevnost.typ === '0/0' && produkt.primeBarvy.pocet === 0) { // bez potisku, jen materiál
      cena = produkt.materialCenaOfset;

    } else if (produkt.obratka.tisk === true) {
      cena = (nastaveni.stroje.ofset.barvaCena * produkt.obratka.pocetBarev) + cenaMyti + (nastaveni.stroje.ofset.prujezdCena * (produkt.pocetArchuOfset * pruchoduStrojem)) + nastaveni.stroje.ofset.obratkaCena + produkt.materialCenaOfset;

    } else {
      cena = (nastaveni.stroje.ofset.barvaCena * produkt.pocetBarev) + cenaMyti + (nastaveni.stroje.ofset.prujezdCena * (produkt.pocetArchuOfset * pruchoduStrojem)) + produkt.materialCenaOfset;
    }

    cena = cena * nastaveni.koeficientCena;
    return cena;
  }



  // TISK DIGITAL
  function calcDigital() {
    // console.log('pocet průjezdu digital: ' + produkt.pocetArchuDigital);
    // console.log('material cena digital: ' + produkt.materialCenaDigital);
    var cena = 0;
    if (produkt.pocetArchuDigital <= 750) { // do 750ks včetně
      if (produkt.barevnost.typ === '1/0') {
        cena = nastaveni.stroje.digital.pripravaCernobile + ((nastaveni.stroje.digital.prujezdDigiCenaDo750_10 - produkt.pocetArchuDigital / 750) * produkt.pocetArchuDigital) + produkt.materialCenaDigital;

      } else if (produkt.barevnost.typ === '1/1') {
        cena = nastaveni.stroje.digital.pripravaCernobile + ((nastaveni.stroje.digital.prujezdDigiCenaDo750_11 - produkt.pocetArchuDigital / 750) * produkt.pocetArchuDigital) + produkt.materialCenaDigital;

      } else if (produkt.barevnost.typ === '4/0' || produkt.barevnost.typ === '3/0' || produkt.barevnost.typ === '2/0') {
        cena = nastaveni.stroje.digital.pripravaBarva + ((nastaveni.stroje.digital.prujezdDigiCenaDo750_40 - 2.44 * produkt.pocetArchuDigital / 750) * produkt.pocetArchuDigital) + produkt.materialCenaDigital;

      } else if (produkt.barevnost.typ === '4/4' || produkt.barevnost.typ === '4/3' || produkt.barevnost.typ === '4/2' || produkt.barevnost.typ === '4/1' || produkt.barevnost.typ === '3/3' || produkt.barevnost.typ === '3/2' || produkt.barevnost.typ === '3/1' || produkt.barevnost.typ === '2/2' || produkt.barevnost.typ === '2/1') {
        cena = nastaveni.stroje.digital.pripravaBarva + ((nastaveni.stroje.digital.prujezdDigiCenaDo750_44 - 2.44 * produkt.pocetArchuDigital / 750) * produkt.pocetArchuDigital) + produkt.materialCenaDigital;
      }
    } else { // nad 750ks
      if (produkt.barevnost.typ === '1/0') {
        cena = nastaveni.stroje.digital.pripravaCernobile + (nastaveni.stroje.digital.prujezdDigiCenaNad750_10 * produkt.pocetArchuDigital) + produkt.materialCenaDigital;

      } else if (produkt.barevnost.typ === '1/1') {
        cena = nastaveni.stroje.digital.pripravaCernobile + (nastaveni.stroje.digital.prujezdDigiCenaNad750_11 * produkt.pocetArchuDigital) + produkt.materialCenaDigital;

      } else if (produkt.barevnost.typ === '4/0' || produkt.barevnost.typ === '3/0' || produkt.barevnost.typ === '2/0') {
        cena = nastaveni.stroje.digital.pripravaBarva + (nastaveni.stroje.digital.prujezdDigiCenaNad750_40 * produkt.pocetArchuDigital) + produkt.materialCenaDigital;

      } else if (produkt.barevnost.typ === '4/4' || produkt.barevnost.typ === '4/3' || produkt.barevnost.typ === '4/2' || produkt.barevnost.typ === '4/1' || produkt.barevnost.typ === '3/3' || produkt.barevnost.typ === '3/2' || produkt.barevnost.typ === '3/1' || produkt.barevnost.typ === '2/2' || produkt.barevnost.typ === '2/1') {
        cena = nastaveni.stroje.digital.pripravaBarva + (nastaveni.stroje.digital.prujezdDigiCenaNad750_44 * produkt.pocetArchuDigital) + produkt.materialCenaDigital;
      }
    }

    // koeficient zdražení uplatňujeme na každou položku zvlášť
    cena = cena * nastaveni.koeficientCena;
    // console.log('cena: ' + cena);
    return cena;
  }



  var tiskTechnologie;
  var tiskFormat;

  produkt.tiskDigital = calcDigital();
  produkt.tiskOfset = calcOfset();

  // console.log(produkt.tiskDigital);
  // console.log(produkt.tiskOfset);

  // automatika
  if (produkt.technologieTisku === 'auto') {

    if (produkt.tiskDigital < produkt.tiskOfset && produkt.primeBarvy.pocet === 0 && produkt.rozmer.sirka < nastaveni.stroje.digital.rozmer.sirka) {
      produkt.tisk = produkt.tiskDigital;
      tiskTechnologie = 'digital';
      tiskFormat = nastaveni.stroje.digital.rozmer.format;
      produkt.pocetArchu = produkt.pocetArchuDigital;

    } else { // pokud je digitál dražší, tiskneme ofsetem
      produkt.tisk = produkt.tiskOfset;
      tiskTechnologie = 'ofset';
      tiskFormat = nastaveni.stroje.ofset.rozmer.format;
      produkt.pocetArchu = produkt.pocetArchuOfset;
    }

  } else if (produkt.technologieTisku === 'digital') {

    getN('#primeBarvy').value = 0;
    produkt.tisk = produkt.tiskDigital;
    tiskTechnologie = 'digital';
    tiskFormat = nastaveni.stroje.digital.rozmer.format;
    produkt.pocetArchu = produkt.pocetArchuDigital;

  } else if (produkt.technologieTisku === 'ofset') {
    // getN('#primeBarvy').disabled = false;
    produkt.tisk = produkt.tiskOfset;
    tiskTechnologie = 'ofset';
    tiskFormat = nastaveni.stroje.ofset.rozmer.format;
    produkt.pocetArchu = produkt.pocetArchuOfset;

  }

  // dekorace výstupu
  if (produkt.primeBarvy.pocet !== 0) {
    produkt.primeBarvy.text = ' + ' + produkt.primeBarvy.pocet + ' PANTONE';
  } else {
    produkt.primeBarvy.text = '';
  }

  if (produkt.tiskDigital === Infinity || produkt.primeBarvy.pocet > 0) {
    produkt.tiskDigitalText = 'nelze';
  } else {
    produkt.tiskDigitalText = zaokrouhlit(produkt.tiskDigital) + ' Kč';
  }


  // SPECIÁLNÍ BARVY RICOH PRO C7200x
  function barvyDigital(typ) {
    switch (typ) {
      case 'lak_10_50':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialLak50 * produkt.pocetArchu);
      case 'lak_11_50':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialLak50 * 2 * produkt.pocetArchu);
      case 'lak_10_100':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialLak100 * produkt.pocetArchu);
      case 'lak_11_100':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialLak100 * 2 * produkt.pocetArchu);
      case 'bila_10_50':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialBila50 * produkt.pocetArchu);
      case 'bila_11_50':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialBila50 * 2 * produkt.pocetArchu);
      case 'bila_10_100':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialBila100 * produkt.pocetArchu);
      case 'bila_11_100':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialBila100 * 2 * produkt.pocetArchu);
      case 'zlata_10_50':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialZlata50 * produkt.pocetArchu);
      case 'zlata_11_50':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialZlata50 * 2 * produkt.pocetArchu);
      case 'zlata_10_100':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialZlata100 * produkt.pocetArchu);
      case 'zlata_11_100':
        return nastaveni.stroje.digital.pripravaBarvaSpecial + (nastaveni.stroje.digital.barvySpecialZlata100 * 2 * produkt.pocetArchu);
      default:
        return 0;
    }
  }

  produkt.barvyDigital.cena = (nastaveni.koeficientCena * barvyDigital(produkt.barvyDigital.typ));

  if (produkt.barvyDigital.typ !== '0') {
    produkt.barvyDigital.text = ', Digitální speciální barva: ' + produkt.barvyDigital.nazev;
    produkt.barvyDigital.textCena = 'Cena digitální speciální barvy: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.barvyDigital.cena))) + ' Kč<br>';
  } else {
    produkt.barvyDigital.text = '';
    produkt.barvyDigital.textCena = '';
  }
  // console.log('Digitální speciální barva: ' + produkt.barvyDigital.cena);


  // LAKOVÁNÍ
  function lakovani(typ) {
    switch (typ) {
      case 'parcial10':
        return nastaveni.lak.parcial.priprava10 + (nastaveni.lak.parcial.prujezd10 * produkt.pocetArchu);
      case 'parcial11':
        return nastaveni.lak.parcial.priprava11 + (nastaveni.lak.parcial.prujezd11 * produkt.pocetArchu);
      case 'plocha10':
        return nastaveni.lak.plocha.priprava10 + (nastaveni.lak.plocha.prujezd10 * produkt.pocetArchu);
      case 'plocha11':
        return nastaveni.lak.plocha.priprava11 + (nastaveni.lak.plocha.prujezd11 * produkt.pocetArchu);
      case 'stroj10':
        return nastaveni.lak.stroj.priprava10 + (nastaveni.lak.stroj.prujezd10 * produkt.pocetArchu);
      case 'stroj11':
        return nastaveni.lak.stroj.priprava11 + (nastaveni.lak.stroj.prujezd11 * produkt.pocetArchu);
      default:
        return 0;
    }
  }
  produkt.lak.cena = (nastaveni.koeficientCena * lakovani(produkt.lak.typ));
  if (produkt.lak.typ !== '0') {
    produkt.lak.text = ', ' + produkt.lak.nazev;
    produkt.lak.textCena = 'Cena lakování: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.lak.cena))) + ' Kč<br>';
  } else {
    produkt.lak.text = '';
    produkt.lak.textCena = '';
  }
  // console.log('Lakování: ' + produkt.lak.cena);



  // falcovani
  function falcovani(typ) {
    // console.log(typ);
    switch (typ) {
      case '0':
        return 0;
      case 'half-fold':
        return nastaveni.falcovani.typ.half_fold.priprava + (nastaveni.falcovani.typ.half_fold.cena * produkt.pocetKusu);
      case 'cross-fold':
        return nastaveni.falcovani.typ.cross_fold.priprava + (nastaveni.falcovani.typ.cross_fold.cena * produkt.pocetKusu);
      case 'u-fold':
        return nastaveni.falcovani.typ.u_fold.priprava + (nastaveni.falcovani.typ.u_fold.cena * produkt.pocetKusu);
      case 'z-fold':
        return nastaveni.falcovani.typ.z_fold.priprava + (nastaveni.falcovani.typ.z_fold.cena * produkt.pocetKusu);
      case 'double-half-fold':
        return nastaveni.falcovani.typ.double_half_fold.priprava + (nastaveni.falcovani.typ.double_half_fold.cena * produkt.pocetKusu);
      case 'roll-fold':
        return nastaveni.falcovani.typ.roll_fold.priprava + (nastaveni.falcovani.typ.roll_fold.cena * produkt.pocetKusu);
      case 'harmonium-fold-3':
        return nastaveni.falcovani.typ.harmonium_fold_3.priprava + (nastaveni.falcovani.typ.harmonium_fold_3.cena * produkt.pocetKusu);
      case 'harmonium-fold-4':
        return nastaveni.falcovani.typ.harmonium_fold_4.priprava + (nastaveni.falcovani.typ.harmonium_fold_4.cena * produkt.pocetKusu);
      case 'harmonium-fold-5':
        return nastaveni.falcovani.typ.harmonium_fold_4.priprava + (nastaveni.falcovani.typ.harmonium_fold_5.cena * produkt.pocetKusu);
      case 'gate-fold':
        return nastaveni.falcovani.typ.gate_fold.priprava + (nastaveni.falcovani.typ.gate_fold.cena * produkt.pocetKusu);
      case 'double-gate-fold':
        return nastaveni.falcovani.typ.double_gate_fold.priprava + (nastaveni.falcovani.typ.double_gate_fold.cena * produkt.pocetKusu);
      default:
        return 0;
    }
  }
  // minimal rozměry po zavření 148x40mm
  // maximal rozměry po zavření a3
  // gramáž: 70-170g

  // 100+ počet lomů x 0,10
  // křížový lom: 200+ počet lomů x 0,10
  // ruční ohyb: početlomů x 0,60
  // přetáčení lomu z falcovačky: početlomů x 0,40
  produkt.falcovani.cena = (nastaveni.koeficientCena * falcovani(produkt.falcovani.typ));
  if (produkt.falcovani.typ !== '0') {
    produkt.falcovani.text = ', Falcování: ' + produkt.falcovani.nazev;
    produkt.falcovani.textCena = 'Cena falcování: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.falcovani.cena))) + ' Kč<br>';
  } else {
    produkt.falcovani.text = '';
    produkt.falcovani.textCena = '';
  }
  // console.log('Falcování: ' + produkt.falcovani.cena);


  // BIGOVÁNÍ
  function bigovani(typ) {
    // console.log(typ);
    switch (typ) {
      case 'half-fold':
        return nastaveni.bigovani.typ.half_fold.priprava + (nastaveni.bigovani.typ.half_fold.cena * produkt.pocetKusu);
      case 'cross-fold':
        return nastaveni.bigovani.typ.cross_fold.priprava + (nastaveni.bigovani.typ.cross_fold.cena * produkt.pocetKusu);
      case 'u-fold':
        return nastaveni.bigovani.typ.u_fold.priprava + (nastaveni.bigovani.typ.u_fold.cena * produkt.pocetKusu);
      case 'z-fold':
        return nastaveni.bigovani.typ.z_fold.priprava + (nastaveni.bigovani.typ.z_fold.cena * produkt.pocetKusu);
      case 'double-half-fold':
        return nastaveni.bigovani.typ.double_half_fold.priprava + (nastaveni.bigovani.typ.double_half_fold.cena * produkt.pocetKusu);
      case 'roll-fold':
        return nastaveni.bigovani.typ.roll_fold.priprava + (nastaveni.bigovani.typ.roll_fold.cena * produkt.pocetKusu);
      case 'harmonium-fold-3':
        return nastaveni.bigovani.typ.harmonium_fold_3.priprava + (nastaveni.bigovani.typ.harmonium_fold_3.cena * produkt.pocetKusu);
      case 'harmonium-fold-4':
        return nastaveni.bigovani.typ.harmonium_fold_4.priprava + (nastaveni.bigovani.typ.harmonium_fold_4.cena * produkt.pocetKusu);
      case 'harmonium-fold-5':
        return nastaveni.bigovani.typ.harmonium_fold_4.priprava + (nastaveni.bigovani.typ.harmonium_fold_5.cena * produkt.pocetKusu);
      case 'gate-fold':
        return nastaveni.bigovani.typ.gate_fold.priprava + (nastaveni.bigovani.typ.gate_fold.cena * produkt.pocetKusu);
      case 'double-gate-fold':
        return nastaveni.bigovani.typ.double_gate_fold.priprava + (nastaveni.bigovani.typ.double_gate_fold.cena * produkt.pocetKusu);
      default:
        return 0;
    }
  }
  produkt.bigovani.cena = (nastaveni.koeficientCena * bigovani(produkt.bigovani.typ));
  if (produkt.bigovani.typ !== '0') {
    produkt.bigovani.text = ', Bigování: ' + produkt.bigovani.nazev;
    produkt.bigovani.textCena = 'Cena bigování: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.bigovani.cena))) + ' Kč<br>';
  } else {
    produkt.bigovani.text = '';
    produkt.bigovani.textCena = '';
  }
  // console.log('Bigování: ' + produkt.bigovani.cena);


  // VYSEK
  // function vysek(typ) {
  // console.log(typ);
  // napad - zmenšit velikost papíru na velikost raznice a přepsat počet kusů na archu - což by samo vypočetlo kolkrát se vejde na aarch (víceprodukce) - zeptat se!

  // pozor, máme výpočet archů podle kusů, který se vejde na velikost tiskového archu - digitál/ofset.
  // do výsekového stroje se vejde jen velikost podle raznice. to zase neplatí, když se výsekové železo musí vyrobit. tam se udělá
  // velikost ideální na tiskový formát a na počet pozic
  // pak teprve platí:
  // pocet kusu vydělit počtem pozic = počet bouchů. počet bouchů vynásobit 

  // }
  // produkt.vysek.cena = (nastaveni.koeficientCena * vysek(produkt.vysek.typ));



  // VRTÁNÍ
  function vrtani(pocetKusu, gramaz, pocetVrtu) {
    if (pocetVrtu === 0) {
      return 0;
    } else {
      return nastaveni.vrtani.priprava + (pocetVrtu * (pocetKusu * gramaz / 4000));
    }
  }
  produkt.vrtani.cena = (nastaveni.koeficientCena * vrtani(produkt.pocetKusu, produkt.material.gramaz, produkt.vrtani.pocetVrtu));
  if (produkt.vrtani.pocetVrtu !== 0) {
    produkt.vrtani.text = ', Vrtání: ' + produkt.vrtani.nazev;
    produkt.vrtani.textCena = 'Cena vrtání: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.vrtani.cena))) + ' Kč<br>';
  } else {
    produkt.vrtani.text = '';
    produkt.vrtani.textCena = '';
  }
  // console.log('Vrtání: ' + produkt.vrtani.cena);


  // PERFORACE
  function perforace(pocetKusu, pocetPerforaci) {
    // 100+ náklad/10
    // console.log(pocetKusu, pocetPerforaci);
    return pocetPerforaci * (nastaveni.perforace.priprava + (pocetKusu / 10));
  }
  produkt.perforace.cena = (nastaveni.koeficientCena * perforace(produkt.pocetKusu, produkt.perforace.pocetPerforaci));

  if (produkt.perforace.pocetPerforaci !== 0) {
    produkt.perforace.text = ', Perforace: ' + produkt.perforace.nazev;
    produkt.perforace.textCena = 'Cena perforace: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.perforace.cena))) + ' Kč<br>';
  } else {
    produkt.perforace.text = '';
    produkt.perforace.textCena = '';
  }
  // console.log('Perforace: ' + produkt.perforace.cena);






  // VÝSEK
  function vysek() {
    fixniNaklady = nastaveni.vysek.priprava
    cenaVysekavani = (produkt.pocetKusu / produkt.vysek.pozicNaVysek) * nastaveni.vysek.cena;
    return fixniNaklady + cenaVysekavani;
  }
  if (produkt.vysek.option !== 0) {
    produkt.vysek.cena = +vysek();
      console.log(produkt.vysek.cena);
      console.log(produkt.vysek.cenaRazniceVyseku);
    produkt.vysek.text = ', Výsek: ' + produkt.vysek.typ + ' (' + produkt.vysek.pozicNaVysek + ' pozic)';
    produkt.vysek.textCenaRaznice = 'Cena raznice výseku: ' + produkt.vysek.cenaRazniceVyseku + ' Kč<br>';
    produkt.vysek.textCena = 'Cena výseku: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.vysek.cena))) + ' Kč<br>';
      console.log(produkt.vysek.text);
      console.log(produkt.vysek.textCenaRaznice);
      console.log(produkt.vysek.textCena);
  } else {
    produkt.vysek.cena = 0;
    produkt.vysek.text = '';
    produkt.vysek.textCenaRaznice = '';
    produkt.vysek.textCena = '';
  }

  
   // PŘEDSEK
   function predsek() {
    fixniNaklady = nastaveni.predsek.priprava; 
    cenaVysekavani = (produkt.pocetKusu / produkt.predsek.pozicNaPredsek) * nastaveni.predsek.cena;
    return fixniNaklady + cenaVysekavani;
  }
  if (produkt.predsek.option !== 0) {
    produkt.predsek.cena = +predsek();
      console.log(produkt.predsek.cena);
    produkt.predsek.text = ', Předsek: ' + produkt.predsek.typ + ' (' + produkt.predsek.pozicNaPredsek + ' pozic)';
    produkt.predsek.textCenaRaznice = 'Cena raznice předseku: ' + produkt.predsek.cenaRaznicePredseku + ' Kč<br>';
    produkt.predsek.textCena = 'Cena předseku: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.predsek.cena))) + ' Kč<br>';
      console.log(produkt.predsek.text);
      console.log(produkt.predsek.textCenaRaznice);
      console.log(produkt.predsek.textCena);
  } else {
    produkt.predsek.cena = 0;
    produkt.predsek.text = '';
    produkt.predsek.textCenaRaznice = '';
    produkt.predsek.textCena = '';
  }

  
     // RAŽBA 
     function razba() {
      fixniNaklady = nastaveni.razba.priprava; 
      cenaVysekavani = (produkt.pocetKusu / produkt.razba.pozicNarazba) * nastaveni.razba.cena;
      return fixniNaklady + cenaVysekavani;
    }
    if (produkt.razba.option !== 0) {
      produkt.razba.cena = +razba();
        console.log(produkt.razba.cena);
      produkt.razba.text = ', Ražba: ' + produkt.razba.typ + ' (' + produkt.razba.pozicNarazba + ' pozic)';
      produkt.razba.textCenaStocku = 'Cena štočku ražby: ' + produkt.razba.cenaRaznicerazbau + ' Kč<br>';
      produkt.razba.textCena = 'Cena ražby: ' + formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.razba.cena))) + ' Kč<br>';
        console.log(produkt.razba.text);
        console.log(produkt.razba.textCenaRaznice);
        console.log(produkt.razba.textCena);
    } else {
      produkt.razba.cena = 0;
      produkt.razba.text = '';
      produkt.razba.textCenaRaznice = '';
      produkt.razba.textCena = '';
    }


    // vypočítáme váhu zakázky pro výpočet ceny a typu dopravy
    function vahaPapiru(sirka, vyska, gramaz, pocetKusu) {
      // Nejprve vypočítáme celkový obsah všech listů v milimetrech čtverečních
      const celkovaPlochaMilimetry = sirka * vyska * pocetKusu;
      // Nyní převedeme celkový obsah na metry čtvereční
      const celkovaPlochaMetry = celkovaPlochaMilimetry / 1000000;
      // Nyní můžeme použít gramáž papíru k výpočtu celkové váhy papíru
      const celkovaVahaGramy = celkovaPlochaMetry * gramaz;
      console.log(celkovaVahaGramy);
      // Nakonec převedeme celkovou váhu na kilogramy
      const celkovaVahaKilogramy = celkovaVahaGramy / 1000;
      // Vraťte výsledek
      return celkovaVahaKilogramy;
    }
    produkt.vahaZakazky = vahaPapiru(produkt.rozmer.sirka, produkt.rozmer.vyska, produkt.material.gramaz, produkt.pocetKusu);
    


  // UTILS (dekorace výstupu)
  if (produkt.nazev !== '') {
    document.title = produkt.nazev;
    produkt.nazev = produkt.nazev + ': ';
  }
  if (objednavka.dodat !== '') {
    objednavka.dodatText = 'Dodání: ' + dayjs(objednavka.dodat).locale('cs').format('D. M. YYYY (dddd)') + '<br><br>';
  } else {
    objednavka.dodatText = '';
  }

  function formatovatCenu(cena) {
    var parts = cena.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  // upravíme ceny podle maloobchodu nebo velkoobchodu
  function typCeny(typZakaznika, cena) {
    if (typZakaznika === 'malo') {
      return cena * 1.1;
    } else {
      return cena;
    }
  }


  // DEBUG INFO
  document.getElementById('technicke').innerHTML = '<p><strong>Info: </strong>digitál: ' +
    produkt.tiskDigitalText +
    ', ofset: ' +
    produkt.tiskOfset +
    ' Kč, tiskneme <strong>' +
    tiskTechnologie +
    '</strong>, na ' +
    tiskFormat +
    ' se vejde ' +
    pocetKusuNaArchu(tiskTechnologie) +
    'x ' +
    produkt.orientace +
    ', spotřebujeme <strong>' +
    produkt.pocetArchu +
    '</strong> ks ' +
    tiskFormat + ' za ' + zaokrouhlit(produkt.materialCenaOfset * nastaveni.koeficientCena) + ' Kč' +
      produkt.obratka.text +
      ', výsledná váha zakázky: ' + zaokrouhlit(produkt.vahaZakazky) + ' kg'
    '</p>';


  // VYSLEDEK
  document.getElementById('mail').innerHTML =
      objednavka.dodatText +
      produkt.nazev +
      produkt.pocetKusu +
      'ks, ' +
      produkt.rozmer.format +
      ', ' +
      produkt.barevnost.text +
      produkt.primeBarvy.text +
      ', ' +
      produkt.material.nazev +
      produkt.barvyDigital.text +
      produkt.lak.text +
      produkt.laminace.text +
      produkt.falcovani.text +
      produkt.bigovani.text +
      produkt.vysek.text +
      produkt.predsek.text +
      produkt.vrtani.text +
      produkt.perforace.text +
      '.<br><br>' +
      'Cena tisku: <strong>' +
      formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.tisk))) +
      '</strong> Kč (' +
      zaokrouhlit(typCeny(zakaznik.kategorie, produkt.cenaZaKus)) +
      ' Kč/ks)<br>' +
      produkt.barvyDigital.textCena +
      produkt.lak.textCena +
      produkt.laminace.textCena +
      produkt.falcovani.textCena +
      produkt.bigovani.textCena +
      produkt.vysek.textCena +
      produkt.vysek.textCenaRaznice +
      produkt.predsek.textCena +
      produkt.predsek.textCenaRaznice +
      produkt.vrtani.textCena +
      produkt.perforace.textCena +
      '<p>DTP: ' +
      produkt.dtp +
      ' Kč<br>' +
      objednavka.doprava.text +
      '<br>Cena celkem: <strong>' +
      formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.cenaCelkem) + produkt.dtp + objednavka.doprava.cena)) +
      '</strong> Kč (' +
      zaokrouhlit((typCeny(zakaznik.kategorie, produkt.cenaCelkem) + produkt.dtp + objednavka.doprava.cena) / produkt.pocetKusu) +
      ' Kč/ks)<br>' +
      'Cena celkem s DPH: <strong>' +
      formatovatCenu(zaokrouhlit((typCeny(zakaznik.kategorie, produkt.cenaCelkem) + produkt.dtp + objednavka.doprava.cena) * nastaveni.dph)) +
      '</strong> Kč</p>';


  document.getElementById('vysledekMobil').innerHTML = formatovatCenu(zaokrouhlit(typCeny(zakaznik.kategorie, produkt.cenaCelkem) + produkt.dtp + objednavka.doprava.cena)) +
  ' Kč (<strong>' +
  zaokrouhlit((typCeny(zakaznik.kategorie, produkt.cenaCelkem) + produkt.dtp + objednavka.doprava.cena) / produkt.pocetKusu) +
  ' Kč/ks</strong>)'

  // console.log(produkt.tisk, produkt.barvyDigital.cena, produkt.lak.cena, produkt.laminace.cena, produkt.falcovani.cena, produkt.bigovani.cena, produkt.vysek.cena, produkt.razba.cena, produkt.lepeni.cena, produkt.vrtani.cena, produkt.perforace.cena, produkt.personalizace.cena, produkt.vazba.cena, produkt.dtp, objednavka.doprava.cena);
}
pocitej();


